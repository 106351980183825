<template>
  <v-container>
    <v-card class="bg-card rounded-xl py-5 mt-5">
      <h4 class="text-center text-h4 mb-2">Modificar usuario</h4>

      <v-row class="mx-5 mx-lg-16 px-lg-16" justify="center">
        <v-col cols="12" md="">
          <v-form ref="form" v-model="valid" lazy-validation>
            <h6 class="text-center text-h6 mb-3">Autenticación</h6>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Nombre de usuario</label
            >
            <v-text-field
              placeholder="Nombre de usuario"
              color="black"
              rounded
              v-model="user.username"
              :rules="[rules.required]"
              required
              solo
              class="mt-2 mb-n3"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Correo electrónico</label
            >
            <v-text-field
              placeholder="Correo electrónico"
              color="black"
              v-model="user.email"
              rounded
              :rules="[rules.required, rules.email]"
              required
              solo
              class="mt-2 mb-n3"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Rol</label
            >
            <v-select
              ref="info"
              placeholder="Seleccione un rol"
              :rules="[rules.required]"
              v-model="user.roleId"
              :items="roles"
              solo
              rounded
              class="mt-2 mb-n3"
            ></v-select>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Ultima conexión</label
            >
            <v-text-field
              placeholder="Ultima conexión"
              color="black"
              rounded
              disabled
              solo
              v-model="user.lastConnect"
              class="mt-2 mb-n3 font-work-sans"
            ></v-text-field>
          </v-form>
        </v-col>
        <v-divider vertical class="my-8 mx-lg-16 d-md-block d-none"></v-divider>

        <v-col cols="12" md="">
          <v-form ref="form2" v-model="valid" lazy-validation>
            <h6 class="text-center text-h6 mb-3">Datos personales</h6>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Cédula</label
            >
            <v-text-field
              placeholder="Cédula"
              color="black"
              v-model="user.idNumber"
              disabled
              solo
              class="mt-2 mb-n3 rounded-xl font-quicksand"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Nombres</label
            >
            <v-text-field
              placeholder="Nombres"
              color="black"
              rounded
              :rules="[rules.required]"
              v-model="user.firstName"
              required
              solo
              class="mt-2 mb-n3"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Apellidos</label
            >
            <v-text-field
              placeholder="Apellidos"
              color="black"
              rounded
              :rules="[rules.required]"
              v-model="user.lastName"
              required
              solo
              class="mt-2 mb-n3"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Cargo</label
            >
            <v-text-field
              placeholder="Cargo"
              color="black"
              rounded
              :rules="[rules.required]"
              v-model="user.position"
              required
              solo
              class="mt-2"
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>

      <div
        class="d-flex flex-column flex-md-row align-center justify-end mx-md-16"
      >
        <router-link custom v-slot="{ navigate }" :to="{ name: 'Admin' }">
          <v-btn
            color="secondary"
            outlined
            class="rounded-lg mr-md-4 my-2 my-md-0"
            @click="navigate"
          >
            <v-icon>fa-times</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Cancelar</span>
          </v-btn>
        </router-link>

        <v-btn
          color="primary"
          class="rounded-lg ml-md-4 my-2 my-md-0 elevation-0"
          @click="submit"
          :loading="loading"
        >
          <v-icon>fa-user-edit</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold">Aceptar</span>
        </v-btn>
      </div>
    </v-card>

    <dialog-edit
      :item="item"
      :dialog="dialogEdit"
      @dismiss="
        () => {
          dialogEdit = false
        }
      "
      @accept="update()"
    />
  </v-container>
</template>

<script>
import Swal from 'sweetalert2'
import Repository from '@/repositories/RepositoryFactory'
import { format } from 'date-fns'
import DialogEdit from '@/components/DialogEdit.vue'
const UserRepository = Repository.get('users')

export default {
  components: { DialogEdit },
  name: 'EditAdmin',
  props: ['id'],
  data: () => ({
    loading: false,
    valid: false,
    show: false,
    dialogEdit: false,
    item: {},
    user: {
      username: '',
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      roleId: '',
      idType: 'V',
      lastConnect: '',
    },
    idTypes: ['V', 'J', 'E'],
    roles: [
      { text: 'Admistrador de Soporte Kuro', value: 1 },
      { text: 'Admistrador Cli', value: 2 },
      { text: 'Gestor de Turnos', value: 3 },
    ],
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      counter: (value) =>
        value?.length >= 8 || 'Contraseña mínima de 8 carácteres.',
      email: (value) => {
        const pattern = /.+@.+/
        return pattern.test(value) || 'Email inválido.'
      },
      number: (value) => {
        const pattern = /[^0-9]/
        return !pattern.test(value) || 'Campo inválido.'
      },
    },
  }),
  created: async function () {
    await this.loadData()
  },
  mounted: async function () {
    try {
      const { data } = await UserRepository.getAllRoles()
      const roles = data.data
      if (roles.length > 0) {
        this.roles = []
        roles.forEach((item) => {
          this.roles.push({
            text: item.description,
            value: item.id,
          })
        })
      }
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    loadData: async function () {
      this.$eventBus.emit('loading-page')
      try {
        const res = await UserRepository.getUser(this.id)
        this.user = res.data.data
        this.user.lastConnect = `${this.formatDate(
          this.user.lastConnection
        )} ${this.formatHours(this.user.lastConnection)}`
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.error : error

        console.log(response)
      }
    },
    submit: async function () {
      this.$refs.form.validate()
      this.$refs.form2.validate()

      setTimeout(async () => {
        if (this.valid) {
          this.item = {
            type: 'usuario',
            id: this.user.idNumber,
            name: `${this.user.firstName} ${this.user.lastName}`,
          }
          this.dialogEdit = true
        }
      }, 500)
    },

    update: async function () {
      this.loading = true
      this.dialogEdit = false

      try {
        const res = await UserRepository.update(this.user, this.id)

        const data = {
          type: 'success',
          title: 'Petición exitosa',
          text: res.data.message,
        }

        this.$store.dispatch('alerts/activeAlerts', data)
        this.$router.back()
      } catch (error) {
        const response = error.response
          ? error.response.data.message ||
            error.response.data.errors?.reduce((acc, cur) => ({
              msg: acc.msg + ', ' + cur.msg,
            })).msg
          : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }
      this.loading = false
    },

    formatDate: (value) => {
      const date = value ? new Date(value) : new Date()
      return format(date, 'dd/MM/yyyy')
    },
    formatHours: (value) => {
      const date = value ? new Date(value) : new Date()
      return date.toLocaleString('es-VE', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    },
  },
}
</script>
